<template>
  <div class="move-area">
    <div class="container">
      <div class="info">
        <div class="mb-1" v-if="image.w">
          尺寸: {{image.w}}×{{image.h}}
        </div>
        位置:
        <span :class="{del: area.fill === 'width'}">{{image.x}}</span>
        <span :class="{del: area.fill === 'height'}">{{image.y}}</span>
        {{image.scale}}
        {{image.rotate}}°
      </div>
      <div class="main">
        <header class="mb-2">
          <b-row>
            <b-col cols="auto">
              <b>
                <fa icon="arrows"/>
                拖动图片调整
              </b>
            </b-col>
            <b-col></b-col>
            <b-col cols="auto">
              <b-link size="sm" @click="resetPos">复位</b-link>
            </b-col>
          </b-row>
        </header>

        <div :style="style" class="image">
          <div class="placeholder" :style="placeholderStyle"></div>
          <div class="note" v-if="text">{{text}}</div>
        </div>

        <footer class="mt-2" v-if="area.fill">
          <b-row align-v="center">
            <b-col cols="auto">
              <b-link size="sm" @click="rotate(-90)" :disabled="loadingPic">
                <fa icon="undo"/>
                向左旋转
              </b-link>
            </b-col>
            <b-col></b-col>
            <b-col cols="auto">
              <b-link size="sm" @click="rotate(90)" :disabled="loadingPic">
                向右旋转
                <fa icon="redo"/>
              </b-link>
            </b-col>
          </b-row>

          <b-row class="mt-2" v-if="zoom">
            <b-col cols="auto">0.5×</b-col>
            <b-col class="px-0">
              <b-input class="d-block" type="range" v-model="scale" min="50" max="150"/>
            </b-col>
            <b-col cols="auto">1.5×</b-col>
          </b-row>
        </footer>
      </div>
      <div class="bottom">
        <slot name="buttons" v-bind="{cancel, resetPos}">
          <b-btn @click="save" block variant="primary">确认调整</b-btn>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from 'lodash'
import { loadImage } from '@/utils/utils'

export default {
  name: 'moveArea',
  props: {
    value: Object,
    zoom: Boolean
  },
  data() {
    return {
      loadingPic: false,
      rotating: 0,
      coords: null,
      placeholderStyle: null,
      area: {
        w: 0,
        h: 0,
        offsetLeft: '',
        offsetTop: '',
        fill: '',
        mode: 0
      },
      image: {
        w: 0,
        h: 0,
        x: '50%',
        y: '50%',
        scale: '100%',
        rotate: 0
      }
    }
  },
  watch: {
    '$store.state.winSize': {
      handler: 'calcSize',
      deep: true
    },
    async pic(val) {
      try {
        this.loadingPic = true
        await loadImage(val)
      } finally {
        this.loadingPic = false
      }
    }
  },
  computed: {
    codeName() {
      return this.value.codeName || this.value.name
    },
    pic() {
      const rotate = this.image.rotate
      let src = this.value.pic || this.value.src
      src = this.$img(src, 800)
      if (rotate) {
        src = src.split('!')[0] + '@r' + rotate + '!' + src.split('!')[1]
      }
      return src
    },
    text() {
      return this.value?.text
    },
    style() {
      let size = [this.image.scale, 'auto']
      if (this.area.fill === 'height') {
        size = ['auto', this.image.scale]
      }
      if (this.value.mode === 2) {
        size = [size[1], size[0]]
      }
      return {
        src: this.loadingPic ? '' : this.pic,
        width: this.area.w + 'px',
        height: this.area.h + 'px',
        'background-repeat': 'no-repeat',
        'background-size': size.join(' '),
        'background-image': 'url(' + this.pic + ')',
        'background-position': [this.image.x, this.image.y].join(' ')
      }
    },
    scale: {
      get() {
        return parseFloat(this.image.scale)
      },
      set(val) {
        this.image.scale = val + '%'
        this.$emit('input', {...this.value, scale: val + '%'})
      }
    }
  },
  async mounted() {
    if ('ontouchstart' in window) {
      this.$el.addEventListener('touchstart', this.onGestureStart)
      this.$el.addEventListener('touchmove', this.onGestureMove)
      this.$el.addEventListener('touchend', this.onGestureEnd)
    } else {
      this.$el.addEventListener('mousedown', this.onGestureStart)
      this.$el.addEventListener('mousemove', this.onGestureMove)
      this.$el.addEventListener('mouseup', this.onGestureEnd)
    }
    document.body.appendChild(this.$el)
    this.original = {...this.value}
    await Promise.all([this.initTemplate(), this.initPic()])
    this.calcSize()
  },
  methods: {
    calcSize() {
      const {area, image} = this
      let imageWidth = image.w
      let imageHeight = image.h
      if (image.rotate === 90 || image.rotate === 270) {
        // 交换宽高
        [imageWidth, imageHeight] = [imageHeight, imageWidth]
      }
      area.fill = imageWidth / area.w >= imageHeight / area.h ? 'height' : 'width'
      this.placeholderStyle = {
        paddingBottom: area.h / area.w * 100 + '%'
      }
      const mainEl = this.$el.querySelector('.main')
      mainEl.querySelector('.image').style.display = 'none'
      const maxWidth = mainEl.clientWidth
      const maxHeight = mainEl.clientHeight - 120
      const ratio = Math.min(maxWidth / area.w, maxHeight / area.h)
      area.w *= ratio
      area.h *= ratio
      mainEl.querySelector('.image').style.display = null
    },
    async initTemplate() {
      if (this.value.w && this.value.h) {
        this.area.w = this.value.w
        this.area.h = this.value.h
        return
      }
      if (this.codeName) {
        // 封面处理
        try {
          const {layers} = await this.$req.get(`https://canvas.xinshu.me/config/${this.codeName}`)
          const picLayer = layers.find(l => l.name === 'pic')
          this.area.mode = picLayer.mode
          this.area.w = picLayer.w
          this.area.h = picLayer.h
        } catch (err) {
        }
      }
    },
    async initPic() {
      let {dw, dh} = this.value
      if (!dw || !dh) {
        const src = this.value.pic || this.value.src
        const result = await this.loadDimension(src)
        dw = result.w
        dh = result.h
      }
      this.image.w = dw
      this.image.h = dh
      this.image.x = this.value.x || '50%'
      this.image.y = this.value.y || '50%'
      this.image.mode = this.value.mode || 0
      this.image.scale = this.value.scale || '100%'
      this.image.rotate = this.value.rotate || 0
    },
    async loadDimension(url) {
      try {
        url = url.split('!')[0].split('#')[0]
        const result = await this.$req.get('https://media2.xinshu.me/info', {params: {url}})
        return {w: result.width, h: result.height}
      } catch (err) {
        return this.loadImage(url)
      }
    },
    cancel() {
      this.$emit('input', null)
    },
    save() {
      this.$emit('save', this.image)
      return this.image
    },

    resetPos() {
      this.image.x = '50%'
      this.image.y = '50%'
      this.image.scale = '100%'
      this.$emit(
        'input',
        {...this.value, x: this.image.x, y: this.image.y, scale: this.image.scale}
      )
    },
    async rotate(deg) {
      const {image, area} = this
      let rotate = image.rotate
      rotate += deg
      if (rotate < 0) {
        rotate = 360 + rotate
      }
      if (rotate >= 360) {
        rotate = 0
      }
      let imageWidth = image.w
      let imageHeight = image.h
      if (rotate === 90 || rotate === 270) {
        [imageWidth, imageHeight] = [imageHeight, imageWidth]
      }
      if (rotate > 0) {
        const key = this.pic.split(/[!@?]/)[0].split('/').slice(3).join('/').replace(/^media\//, '')
        await this.$req.post('https://media2.xinshu.me/process', {source: key, rotate})
      }
      image.rotate = rotate
      area.fill = imageWidth / area.w >= imageHeight / area.h ? 'height' : 'width'
      if (area.fill === 'width') {
        image.x = '50%'
      } else {
        image.y = '50%'
      }
      this.$emit('input', {...this.value, rotate, x: image.x, y: image.y})
    },
    onGestureStart(e) {
      if (this.getDelegate(e, '.move-area .image')) {
        const touch = e.touches ? e.touches[0] : e
        this.coords = {
          x: touch.clientX,
          y: touch.clientY
        }
      } else {
        this.coords = null
      }
    },
    onGestureMove(e) {
      if (this.coords) {
        e.preventDefault()
        e.stopPropagation()
        const touch = e.touches ? e.touches[0] : e
        const coords = this.coords
        if (coords) {
          const area = this.area
          const image = this.image
          const sign = (this.scale < 100 || this.area.mode === 2) ? -1 : 1

          const deltaX = (coords.x - touch.clientX) * sign
          const deltaY = (coords.y - touch.clientY) * sign

          let posX = image.x
          let posY = image.y
          posX = parseFloat(posX) + deltaX / 5
          posY = parseFloat(posY) + deltaY / 5
          posX = Math.min(Math.max(posX, 0), 100)
          posY = Math.min(Math.max(posY, 0), 100)
          posX = round(posX, 1) + '%'
          posY = round(posY, 1) + '%'

          if (this.scale === 100) {
            if (area.fill === 'height') {
              posY = '50%'
            } else {
              posX = '50%'
            }
          }

          image.x = posX
          image.y = posY

          this.coords = {
            x: touch.clientX,
            y: touch.clientY
          }
        }
      }
    },
    onGestureEnd(e) {
      if (this.coords) {
        this.coords = null
        this.$emit('input', {...this.value, x: this.image.x, y: this.image.y})
      }
    },
    getDelegate(e, selector) {
      let target = e.target
      while (target) {
        if (!target) {
          return null
        }
        if (target.matches(selector)) {
          return target
        }
        target = target.parentElement
      }
    }
  },
  beforeDestroy() {
    this.$el.removeEventListener('touchstart', this.onGestureStart)
    this.$el.removeEventListener('touchmove', this.onGestureMove)
    this.$el.removeEventListener('touchend', this.onGestureEnd)
    this.$el.removeEventListener('mousedown', this.onGestureStart)
    this.$el.removeEventListener('mousemove', this.onGestureMove)
    this.$el.removeEventListener('mouseup', this.onGestureEnd)
    this.$el?.remove?.()
  }
}
</script>

<style lang="scss" scoped>
.move-area {
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  user-select: none;
  overflow: hidden;
  background-color: #333;
  color: #fff;

  .container {
    padding: 1rem;
    padding-bottom: 2rem;
    max-width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .info {
    border-radius: 8px;
    background-color: #3f3f3f;
    padding: .5rem;
    border: 1px solid #2c2c2c;
    color: #ccc;

    .del {
      text-decoration: line-through;
    }
  }

  .slider {
    width: 560px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .bottom {
    max-width: 100%;
    width: 560px;
    margin-left: auto;
    margin-right: auto;
  }

  .tip {
    position: fixed;
    top: 1em;
    left: 1em;
    right: 1em;
    color: #fff;
    text-align: center;
    z-index: 25;
    margin-left: auto;
    margin-right: auto;
    max-width: 12em;
    box-shadow: $box-shadow;
  }

  .ghost {
    max-width: none;
    width: 100%;
    height: 100%;
    opacity: .5;
    position: relative;
    z-index: -1;

    &.restrict-h {
      width: auto;
    }

    &.restrict-v {
      height: auto;
    }
  }

  .main {
    padding: 1rem 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image {
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    cursor: move;
    border: 1px solid rgba(255, 255, 255, .5);
    background-color: rgba(255, 255, 255, .5);;
    margin-left: auto;
    margin-right: auto;
    border-radius: 4px;
    width: 100%;

    .placeholder {
      opacity: 0;
      pointer-events: none;
    }

    &:before, &:after {
      display: block;
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      border: 1px solid rgba(255, 255, 255, .5);
      box-shadow: 0 0 2px -1px rgba(0, 0, 0, 1);
    }

    &:before {
      width: 100%;
      height: 33.3%;
      border-left: 0;
      border-right: 0;
    }

    &:after {
      height: 100%;
      width: 33.3%;
      border-top: 0;
      border-bottom: 0;
    }
  }

  ::v-deep .buttons {
    .col + .col {
      padding-left: 2px;
    }
  }
}

.note {
  position: absolute;
  bottom: 1em;
  right: 1em;
  max-width: 90%;
  text-align: right;
  pointer-events: none;
  text-shadow: 0 0 .5em rgba(0, 0, 0, .5);
}
</style>
